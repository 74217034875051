import React from 'react'
import { Container } from 'reactstrap'

const NotFoundPage = () => (
  <Container>
    <div style={{height: '75vh'}} className="text-center py-8">
      <h1>404 NOT FOUND</h1>
      <p>The page you are trying to display does not exist</p>
    </div>
  </Container>
)

export default NotFoundPage
